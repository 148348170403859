import React from "react";
import { useProfileContext } from "../../context/ProfileContext";
import Socials from "../../components/Socials";
import { CiLocationOn } from "react-icons/ci";
import { RiSpeakFill } from "react-icons/ri";
import { useEducationContext } from "../../context/EducationContext";
import EducationCard from "./components/EducationCard";
import { useResumeContext } from "../../context/ResumeContext";
import ResumeCard from "./components/ResumeCard";
import { useProjectsContext } from "../../context/ProjectContext";
import ProjectCard from "../Projects/components/ProjectCard";
import { useSkillsContext } from "../../context/SkillsContext";
import { LiaFileDownloadSolid } from "react-icons/lia";

function ResumePage() {
  const { userProfile } = useProfileContext();
  const { allEducation } = useEducationContext();
  const { resumeData } = useResumeContext();
  const { allProjects } = useProjectsContext();
  const { allSkills } = useSkillsContext();

  return (
    <div className="pt-24 pb-28 md:p-12 px-8 overflow-hidden">
      <div className="flex justify-end mb-4">
        <a href={userProfile?.resumeLink} download>
          <LiaFileDownloadSolid
            size={30}
            className="text-primary animate-bounce"
          />
        </a>
      </div>

      {/* Profile */}
      <div className="flex flex-col md:flex-row md:gap-x-6">
        <div
          className="flex w-full flex-col md:w-1/3 justify-center items-center gap-x-6"
          data-aos="fade-right"
        >
          <div className="h-32 w-32 rounded-full bg-bgDarker">
            <img
              src={userProfile?.profilePhoto}
              alt="Profile Photo"
              className="rounded-full h-32 w-32 object-cover object-center"
            />
          </div>

          <div className="flex flex-col justify-center text-center">
            <p className="text-gray-300 text-xl font-extrabold mt-1">
              {userProfile?.firstName} {userProfile?.lastName}
            </p>
            <p className="text-gray-300 text-md font-bold">
              {userProfile?.profession || "Software Engineer"}
            </p>

            <div className="flex items-center gap-x-2 justify-center text-sm text-gray-300">
              <div className="flex items-center gap-x-2 ">
                <CiLocationOn /> <p>{userProfile?.location}</p>
              </div>
              |
              <div className="flex justify-center items-center gap-x-2">
                <RiSpeakFill /> <p>{userProfile?.language} - Native</p>
              </div>
            </div>

            <div className="flex justify-center">
              <Socials />
            </div>
          </div>
        </div>

        <div
          className="flex flex-col w-full md:w-2/3 mt-4 md:mt-0"
          data-aos="fade-left"
        >
          <h2 className="text-3xl font-extrabold text-gray-300">Profile</h2>
          <p className="text-gray-300 text-[15px] mt-1">
            {userProfile?.profile}
          </p>
        </div>
      </div>

      <div className="mt-6 flex flex-col gap-y-6">
        {/* Skills & Tools */}
        <div className="mt-6" data-aos="fade-up">
          {/* title */}
          <p className="text-gray-300 border-b border-gray-300 font-extrabold">
            PROGRAMMING LANGUAGES AND TOOLS
          </p>

          <div className="flex flex-wrap gap-3 mt-4">
            {allSkills &&
              allSkills.map((skill) => {
                return (
                  <p
                    className="text-primary border border-primary rounded-full px-[4px] text-sm w-min"
                    key={skill?._id}
                  >
                    {skill?.skill}
                  </p>
                );
              })}
          </div>
        </div>

        {/* Experience */}
        <div className="mt-6">
          <p
            className="text-gray-300 border-b border-gray-300 font-extrabold"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            PRFESSIONAL EXPERIENCE
          </p>

          <div className="flex flex-col gap-y-3 mt-4">
            {resumeData &&
              resumeData.map((resume) => {
                return <ResumeCard {...resume} key={resume?._id} />;
              })}
          </div>
        </div>

        {/* Education */}
        <div className="mt-6">
          <p
            className="text-gray-300 border-b border-gray-300 font-extrabold"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            EDUCATION
          </p>

          <div className="flex flex-col gap-y-3 mt-4">
            {allEducation &&
              allEducation.map((education) => {
                return <EducationCard {...education} key={education?._id} />;
              })}
          </div>
        </div>

        {/* Projects */}
        <div className="mt-6">
          <p className="text-gray-300 border-b border-gray-300 font-extrabold">
            PROJECTS
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-4">
            {allProjects &&
              allProjects.slice(0, 6).map((project) => {
                return <ProjectCard project={project} key={project?._id} />;
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResumePage;
