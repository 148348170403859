import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaMedium } from "react-icons/fa6";
import { FaDev } from "react-icons/fa";
import { useProfileContext } from "../context/ProfileContext";

export default function Socials() {
  const { userProfile } = useProfileContext();

  return (
    <div className="flex flex-row gap-4 py-2">
      <a
        className="text-white hover:shadow-md hover:shadow-amber-400 rounded-xl"
        href={userProfile?.github}
        target={"_blank"}
        rel="noreferrer"
      >
        <FaGithub size={20} />
      </a>

      <a href={userProfile?.linkedIn} target={"_blank"} rel="noreferrer">
        <FaLinkedin
          className="text-sky-600 hover:shadow-md hover:shadow-amber-400"
          size={20}
        />
      </a>
      <a href={userProfile?.twitter} target={"_blank"} rel="noreferrer">
        <BsTwitterX
          className="text-white hover:shadow-md hover:shadow-amber-400"
          size={20}
        />
      </a>

      <a
        href="https://medium.com/@iamdveloper"
        target={"_blank"}
        rel="noreferrer"
      >
        <FaMedium
          className="text-white hover:shadow-md hover:shadow-amber-400"
          size={20}
        />
      </a>

      <a href="https://dev.to/iamdveloper" target={"_blank"} rel="noreferrer">
        <FaDev
          className="text-white hover:shadow-md hover:shadow-amber-400"
          size={20}
        />
      </a>
    </div>
  );
}
