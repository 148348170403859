import React from "react";

function ProjectImages(props) {
  return (
    <div className="">
      {props.images ? (
        props.images.map((image, i) => {
          return <img key={i} src={image} className="mt-4 max-h-[500px] object-contain" />;
        })
      ) : (
        <></>
      )}
    </div>
  );
}

export default ProjectImages;
