import React, { useEffect } from "react";
import SectionTitle from "../../components/SectionTitle";
import ProjectCard from "./components/ProjectCard";
import { useProjectsContext } from "../../context/ProjectContext";
import ProjectShimmer from "./components/ProjectShimmer";

const ProjectPage = () => {
  const { getAllProjects, allProjects, isLoading } = useProjectsContext();

  useEffect(() => {
    getAllProjects();
  }, []);

  // console.log(allProjects);

  return (
    <div className="px-8 py-24 md:p-12 h-full overflow-hidden flex flex-col">
      <SectionTitle pageName={"Projects"} title={"All Projects"} />

      {!isLoading && allProjects.length === 0 && (
        <div className="flex flex-col w-full items-center justify-center text-gray-400 h-full text-xl text-center mt-8">
          <p>
            Oopsie-doodle! My projects are backstage getting glam for their big
            code reveal.
          </p>
          <p className="text-primary">
            It's not just a showcase; it's a tech fashion show! 💄👩‍💻
          </p>
          <p>Stay tuned for the glamour! 🚀😺</p>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-12">
        {isLoading
          ? Array.from({ length: 6 }).map((_, index) => (
              <ProjectShimmer key={index} />
            ))
          : allProjects.map((project) => (
              <ProjectCard key={project._id} project={project} />
            ))}
      </div>
    </div>
  );
};

export default ProjectPage;
