import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { FaGithub, FaLaptopCode } from "react-icons/fa";
import { formatDate } from "../../../utils/formatDate";
import { IoIosCloseCircle } from "react-icons/io";
import { GoLinkExternal } from "react-icons/go";
import { TiArrowRight } from "react-icons/ti";
import { MdFeaturedPlayList } from "react-icons/md";
import ProjectImages from "./ProjectImages";
import { RiScreenshotFill } from "react-icons/ri";

function ProjectDetailsModal(props) {
  return (
    <>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-150" onClose={props.onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-scroll">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="mt-12 w-full lg:mt-0 md:min-w-[60%] md:max-w-[60%] min-h-screen bg-[#242430] transform overflow-hidden text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 h-[400px] bg-cover bg-center"
                    style={{
                      backgroundImage: `url(${props.project?.projectCoverImage})`,
                    }}
                  >
                    <button
                      onClick={props.onClose}
                      className="text-black z-10 self-right float-right bg-bgDarker"
                    >
                      <IoIosCloseCircle className="text-primary" size={30} />
                    </button>
                  </Dialog.Title>

                  <Dialog.Description>
                    <div className="p-8 bg-[#242430]">
                      <div className="flex flex-col-reverse md:flex-row md:justify-between">
                        <div className="flex flex-col">
                          <h3 className="text-primary font-semibold text-xl">
                            {props.project?.projectName}
                          </h3>
                        </div>

                        <div className="flex mb-4 justify-between md:mb-0 md:justify-center">
                          {props.project?.projectViewLink && (
                            <a
                              href={props.project?.projectViewLink}
                              target={"_blank"}
                              className="mr-3 text-bgDarker text-sm flex flex-row hover:shadow-amber-500 hover:shadow-md bg-primary px-2 py-[4px] rounded-xl h-max gap-2"
                            >
                              <p className="self-center">View</p>
                              <span className="self-center">
                                <GoLinkExternal />
                              </span>
                            </a>
                          )}

                          {props.project?.projectGithubLink && (
                            <a
                              href={props.project?.projectGithubLink}
                              target={"_blank"}
                              className="text-white text-sm bg-black px-3 py-[4px] flex items-center gap-2 rounded-xl hover:shadow-amber-500 hover:shadow-md h-max"
                            >
                              <span className="mr-1 text-sm">Source Code</span>
                              <FaGithub />
                            </a>
                          )}
                        </div>
                      </div>

                      <div className="my-3">
                        <p className="text-sm mt-2 text-gray-300">
                          {props.project?.projectDescription}
                        </p>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 text-gray-300 text-sm mt-4">
                        <div className="flex items-center justify-between w-full md:w-4/6">
                          <p className="text-primary">Project Category:</p>
                          <p className="text-sm text-white rounded-xl px-2 bg-blue-800">
                            {/* TODO: Remove the "Web" */}
                            {props.project?.projectCategory}
                          </p>
                        </div>

                        <div className="flex items-center justify-between w-full md:w-3/5">
                          <p className="text-primary">Client:</p>
                          <span className="text-gray-400">
                            {props.project?.projectClient}
                          </span>
                        </div>
                      </div>

                      <hr className="my-6 border-gray-500" />

                      <div className="flex flex-col">
                        <div className="md:mr-12">
                          <h3 className="text-xl text-primary flex gap-x-2 items-center">
                            <FaLaptopCode /> <span>Tech-Stack</span>
                          </h3>
                          <ul className="flex gap-2 mt-2 flex-wrap">
                            {props.project?.projectStack.map((stack, i) => {
                              return (
                                <p
                                  key={i}
                                  className="text-primary border-[1px] border-primary px-2 text-sm flex-wrap rounded-full"
                                >
                                  {stack}
                                </p>
                              );
                            })}
                          </ul>
                        </div>

                        <div className="mt-8">
                          <h3 className="text-xl text-primary flex gap-x-2 items-center">
                            <MdFeaturedPlayList /> <span>Features</span>
                          </h3>

                          <ul className="mt-1">
                            {props.project?.projectFeatures ? (
                              props.project?.projectFeatures.map(
                                (feature, i) => {
                                  return (
                                    <p
                                      className="pb-4 text-gray-400 flex text-sm"
                                      key={i}
                                    >
                                      <span className="pt-[5px] text-primary pr-2">
                                        <TiArrowRight size={18} />
                                      </span>
                                      {feature}
                                    </p>
                                  );
                                }
                              )
                            ) : (
                              <></>
                            )}
                          </ul>
                        </div>
                      </div>

                      {/* images */}
                      <div className="mt-2">
                        <h3 className="text-xl text-primary flex gap-x-2 items-center">
                          <RiScreenshotFill /> <span>Snapshots</span>
                        </h3>

                        <div className="flex justify-center">
                          <ProjectImages
                            images={props.project?.projectImages}
                          />
                        </div>
                      </div>
                    </div>
                  </Dialog.Description>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default ProjectDetailsModal;
