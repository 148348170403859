import React, { createContext, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";

// Assuming you have an API file for resume data
import { apiConnect } from "../api/axios";

const ResumeContext = createContext();

export const ResumeProvider = ({ children }) => {
  const [resumeData, setResumeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function handleError(error) {
    const { response } = error;
    // toast.error(
    //   response?.data?.message ?? "An error occurred, please try again!",
    //   { position: "top-center" }
    // );
  }

  const getResumeData = async () => {
    try {
      setIsLoading(true);
      const response = await apiConnect.getAllResume();

      if (response.status === 200) {
        setResumeData(response.data);
      } else {
        toast.error("Failed to fetch resume data. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getResumeData();
  }, []);

  // Add more functions to update resume data if needed

  return (
    <ResumeContext.Provider
      value={{
        resumeData,
        isLoading,
        getResumeData,
        // Add other functions or state variables as needed
      }}
    >
      {children}
    </ResumeContext.Provider>
  );
};

export const useResumeContext = () => {
  const context = useContext(ResumeContext);
  if (!context) {
    throw new Error("useResumeContext must be used within a ResumeProvider");
  }
  return context;
};
