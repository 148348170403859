import React from "react";
import { CiImageOn } from "react-icons/ci";

function ProjectShimmer() {
  return (
    <div class="shadow rounded-md w-full h-[240px] bg-bgDarker">
      <div className="flex items-center justify-center w-full pt-3">
        <CiImageOn
          size={120}
          className="text-gray-700 animate-pulse opacity-60"
        />
      </div>

      <div class="animate-pulse flex flex-col mt-2 p-4 opacity-80">
        <div className="bg-gray-700 animate-pulse w-full h-4 rounded-full"></div>
        <div className="bg-gray-700 animate-pulse w-1/2 h-3 rounded-full mt-2"></div>
      </div>
    </div>
  );
}

export default ProjectShimmer;
