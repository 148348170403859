import React, { createContext, useContext, useState } from "react";
import { apiConnect } from "../api/axios";
import toast from "react-hot-toast";

const ContactContext = createContext();

export const ContactProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  function handleError(error) {
    const { response } = error;
    // toast.error(
    //   response?.data?.message ?? "An error occurred, please try again!",
    //   { position: "top-center" }
    // );
  }

  const sendEmail = async (emailData) => {
    try {
      setIsLoading(true);

      // Assuming you have an API endpoint to send emails
      const response = await apiConnect.sendEmail(emailData);

      if (response.status === 200) {
        toast.success("Email sent successfully!");
      } else {
        toast.error("Failed to send email. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ContactContext.Provider
      value={{
        isLoading,
        sendEmail,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};

export const useContactContext = () => {
  const context = useContext(ContactContext);
  if (!context) {
    throw new Error("useContactContext must be used within a ContactProvider");
  }
  return context;
};
