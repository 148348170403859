import { Outlet } from "react-router-dom";
import { useProfileContext } from "../../context/ProfileContext";
import LoadingScreen from "../LoadingScreen";

const MinimalLayout = () => {
  const { isSettingUp, isLoading } = useProfileContext();

  return (
    <div>
      {isSettingUp && isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="flex">
          {/* site content */}
          <main className="flex-1">
            <div className="min-h-[100vh] md:pt-0 bg-bgDark lg:flex lg:justify-center">
              <div className="lg:max-w-[1080px] lg:w-full">
                <Outlet />
              </div>
            </div>
          </main>
        </div>
      )}
    </div>
  );
};

export default MinimalLayout;
