import { Navigate } from "react-router-dom";
import ApplicationPage from "../pages/Applications/ApplicationPage";
import MinimalLayout from "../components/Layout/MinimalLayout";

const NoSidebarRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/why",
      children: [
        {
          path: ":searchTag",
          element: <ApplicationPage />,
        },
        {
          path: "",
          element: <Navigate to="/" />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ],
};

export default NoSidebarRoutes;
