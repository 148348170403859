import React, { useState } from "react";
import { GoLinkExternal } from "react-icons/go";
import ProjectDetailsModal from "./ProjectDetailsModal";

function ProjectCard({ project }) {

  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <div
      className="relative bg-bgDarker rounded-lg shadow-lg overflow-hidden group"
      data-aos="zoom-in-up"
      onClick={openModal}
    >
      <img
        className="w-full h-[250px] md:h-[300px] object-cover object-center overflow-hidden transform scale-100 transition-transform duration-300 group-hover:scale-110"
        src={`${project?.projectCoverImage}`}
        alt={project?.projectName}
      />

      <div className="md:absolute bottom-0 md:top-32 w-full h-full bg-bgDarker md:bg-bgDarker/90 transform md:translate-y-full transition-transform duration-500 group-hover:translate-y-0">
        <div className="p-6 text-white">
          <h2 className="text-xl text-primary font-bold mb-2">
            {project?.projectName}
          </h2>
          <p className="my-4 text-sm h-10 text-ellipsis overflow-hidden text-gray-400">
            {project?.projectDescription}
          </p>

          <div className="flex justify-between items-center mt-4">
            <button
              className="text-primary font-bold hover:text-gray-300 text-sm"
              onClick={openModal}
            >
              Learn More
            </button>

            {project?.projectViewLink && (
              <a
                href={project?.projectViewLink}
                target="_blank"
                rel="noreferrer"
              >
                <GoLinkExternal />
              </a>
            )}
          </div>

          <ProjectDetailsModal
            project={project}
            isOpen={isOpen}
            onClose={closeModal}
          />
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
