import React from "react";
import SectionTitle from "../../components/SectionTitle";
import Map from "./components/Map";
import ContactForm from "./components/ContactForm";

function Contact() {
  return (
    <div
      className="px-4 md:px-12 pt-16 md:pt-12"
      data-aos="fade-right"
      data-aos-duration="1200"
    >
      <div className="w-full h-auto">
        <div className="w-full h-auto float-left mb-[35px]">
          <div className="w-full h-auto flex justify-between items-end">
            <SectionTitle pageName={"Contact"} title={"Get in Touch"} />
          </div>
        </div>

        <Map />

        <div className="pt-12 pb-24">
          {/* <div className="flex flex-col md:flex-row md:gap-x-8"> */}

          <div className="flex flex-col mb-6">
            {/* <h3 className="text-2xl md:text-3xl font-bold text-gray-300 mb-4 uppercase text-center">
                Don't be Shy!
              </h3> */}

            <h3 className="text-md text-gray-300 mb-4 text-center md:text-left">
              Get in touch for projects, inquiries, or just to say hi! I'm ready
              to chat—fill out the form below and let's make things happen.
            </h3>
          </div>

          {/* <div className="border-r-[1px] border-gray-600"></div> */}

          <div className="w-full">
            <ContactForm />
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default Contact;
