import React from "react";

function TechCard({ name, logo }) {
  return (
    <div
      className="h-[80px] w-15 relative overflow-hidden border border-primary/20 rounded-2xl shadow-primary/10 shadow-2xl hover:shadow-primary/50"
      data-aos="zoom-in-up"
    >
      {logo && (
        <img
          className="absolute inset-0 w-full h-full object-cover"
          src={`${logo}`}
          alt={name}
        />
      )}
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="bg-bgDarker bg-opacity-80 text-white text-center flex items-center justify-center w-full h-full font-bold">
          {name}
        </div>
      </div>
    </div>
  );
}

export default TechCard;
