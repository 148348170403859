import React, { createContext, useContext, useState } from "react";
import { apiConnect } from "../api/axios";
import toast from "react-hot-toast";

const ApplicationContext = createContext();

export const AppProvider = ({ children }) => {
  const [applicationDetails, setApplicationDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function handleError(error) {
    const { response } = error;
    toast.error(
      response?.data?.message ?? "An error occurred, please try again!",
      { position: "top-center" }
    );
  }

  const searchByTag = async (tag) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.getJobApplicationByTag(tag);

      if (response.status === 200) {
        // Handle successful response
        setApplicationDetails(response.data);
      } else {
        toast.error("Failed to search by tag. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Add more functions as needed for your application

  return (
    <ApplicationContext.Provider
      value={{
        applicationDetails,
        isLoading,
        searchByTag,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(ApplicationContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
