import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useContactContext } from "../../../context/ContactContext";
import { BeatLoader } from "react-spinners";

const ContactForm = () => {
  const { isLoading, sendEmail } = useContactContext();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      subject: Yup.string().required("Required"),
      message: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      await sendEmail(values);
      formik.resetForm();
    },
  });

  return (
    <div className="text-gray-400 w-[100%]">
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-6">
          <input
            type="text"
            id="name"
            name="name"
            className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
              formik.touched.name && formik.errors.name
                ? "border-red-500"
                : "border-none"
            }`}
            placeholder="Your Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name && (
            <div className="text-red-500 text-sm mt-2">
              {formik.errors.name}
            </div>
          )}
        </div>
        <div className="mb-6">
          <input
            type="email"
            id="email"
            name="email"
            className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
              formik.touched.email && formik.errors.email
                ? "border-red-500"
                : "border-none"
            }`}
            placeholder="Your Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="text-red-500 text-sm mt-2">
              {formik.errors.email}
            </div>
          )}
        </div>
        <div className="mb-6">
          <input
            type="text"
            id="subject"
            name="subject"
            className={`text-sm w-full p-4 rounded-lg bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
              formik.touched.subject && formik.errors.subject
                ? "border-red-500"
                : "border-none"
            }`}
            placeholder="Subject"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.subject}
          />
          {formik.touched.subject && formik.errors.subject && (
            <div className="text-red-500 text-sm mt-2">
              {formik.errors.subject}
            </div>
          )}
        </div>
        <div className="mb-6">
          <textarea
            id="message"
            name="message"
            className={`text-sm w-full p-4 rounded-lg min-h-[150px] bg-[#242430] focus:outline-none focus:shadow-md focus:shadow-amber-400/30 border ${
              formik.touched.message && formik.errors.message
                ? "border-red-500"
                : "border-none"
            }`}
            placeholder="Your Message"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
          />
          {formik.touched.message && formik.errors.message && (
            <div className="text-red-500 text-sm mt-2">
              {formik.errors.message}
            </div>
          )}
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            role="submit"
            disabled={isLoading}
            className="bg-transparent hover:bg-amber-400 text-sm text-amber-400 py-2 px-12 border border-amber-400 hover:text-[#1E1E28] rounded-lg"
          >
            {isLoading ? (
              <div className="flex items-center justify-center gap-4 ">
                <span>Sending Message</span>
                <BeatLoader size={8} className="pt-[2px] text-primary" />
              </div>
            ) : (
              `Send Message`
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
