import React, { createContext, useContext, useEffect, useState } from "react";
import { apiConnect } from "../api/axios";
import toast from "react-hot-toast";

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSettingUp, setIsSettingUp] = useState(false);

  function handleError(error) {
    const { response } = error;
    // toast.error(
    //   response?.data?.message ?? "An error occurred, please try again!",
    //   { position: "top-center" }
    // );
  }

  const getUserProfile = async () => {
    try {
      setIsSettingUp(true);
      setIsLoading(true);
      const response = await apiConnect.getProfile();

      // console.log(response.data);

      if (response.status === 200) {
        setUserProfile(response.data);
      } else if (response.status === 404) {
        toast.error("User profile not found.");
      } else {
        toast.error("Failed to fetch user profile. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
      setIsSettingUp(false);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <ProfileContext.Provider
      value={{
        userProfile,
        isLoading,
        isSettingUp,
        getUserProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfileContext = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error("useProfileContext must be used within a ProfileProvider");
  }
  return context;
};
