import React from "react";
import { TiArrowRight } from "react-icons/ti";
import { formatDate } from "../../../utils/formatDate";
import { MdWorkHistory } from "react-icons/md";

function ResumeCard({
  description,
  startDate,
  endDate,
  company,
  position,
  skillsUsed,
  isPresent,
  location,
}) {
  return (
    <div
      className="border-l border-gray-600 h-max bg-bgDarker pb-3 pr-4 rounded-tl-lg"
      data-aos="fade-up"
    >
      <div className="flex items-center gap-x-5">
        <MdWorkHistory
          className="text-primary bg-bgDarker p-[6px] rounded-full -ml-4"
          size={35}
        />
      </div>

      <div className="pl-6 md:pl-10 -mt-7">
        <div className="flex flex-col md:flex-row md:justify-between">
          <div>
            <h5 className="uppercase text-lg text-primary">{position}</h5>
            <h6 className="text-gray-400 text-sm italic">
              {company}
              {location ? ` - ${location}` : ""}
            </h6>
          </div>

          <span className="px-[10px] w-max h-max bg-gray-700 text-gray-300 rounded-full text-sm uppercase items-center flex my-2 md:my-0">
            {formatDate(startDate)}
            {isPresent ? "-Present" : endDate ? "- " + formatDate(endDate) : ""}
          </span>
        </div>

        {skillsUsed && (
          <div className="mt-2 flex gap-2 flex-wrap">
            {skillsUsed?.map((val, i) => {
              return (
                <p
                  key={i}
                  className="text-primary border-[1px] border-primary px-2 text-sm flex-wrap rounded-full"
                >
                  {val}
                </p>
              );
            })}
          </div>
        )}

        {description.split("\n").map((val, i) => {
          return (
            <p className="pt-4 text-gray-400 flex text-sm items-center" key={i}>
              <span className="pt-[5px] text-primary pr-2">
                <TiArrowRight size={18} />
              </span>
              {val}
            </p>
          );
        })}
      </div>
    </div>
  );
}

export default ResumeCard;
