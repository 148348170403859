import axios from "axios";

class Api {
  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });

    // this.api.interceptors.request.use(
    //   (config) => {
    //     const token = localStorage.getItem("authToken");
    //     if (token) {
    //       config.headers = {
    //         Authorization: `Bearer ${token}`,
    //       };
    //     }
    //     return config;
    //   },
    //   (error) => {
    //     console.log(error);
    //     throw error;
    //   }
    // );
  }

  // ============ Admin Auth Routes ===============

  getProfile() {
    return this.api.get("/me");
  }
  // ================================================================

  // =================== Admin Blogs Routes ===================

  getAllBlogs() {
    return this.api.get("/blog");
  }

  getBlogById(id) {
    return this.api.get(`/blog/${id}`);
  }

  // ==========================================================

  // ============== Education Routes =============================

  getAllEducation() {
    return this.api.get("/education");
  }

  getEducationById(id) {
    return this.api.get(`/education/${id}`);
  }

  // =========================================================

  // ================ Projects Routes ========================

  getAllProjects() {
    return this.api.get("/projects");
  }

  getProjectsById(id) {
    return this.api.get(`/projects/${id}`);
  }

  // =======================================================

  // ================ Resume/Work Experience routes =========================

  getAllResume() {
    return this.api.get("/work-experience");
  }

  getResumeById(id) {
    return this.api.get(`/work-experience/${id}`);
  }

  // ========================================================

  // ================ Skills Routes =========================

  getAllSkills() {
    return this.api.get("/skills");
  }

  getSkillById(id) {
    return this.api.get(`/skills/${id}`);
  }
  // ========================================================

  sendEmail(emailData) {
    return this.api.post("/email/send", emailData);
  }

  // ============ Job applications ====================

  getAllSentApplications() {
    return this.api.get("/applications");
  }

  getJobApplicationByTag(tag) {
    return this.api.get(`/applications/${tag}`);
  }
}

export const apiConnect = new Api();
