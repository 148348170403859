import { useEffect, useState } from "react";
import { useRef } from "react";
// import SubMenu from "./SubMenu";
import { motion } from "framer-motion";
import logo from "../../assets/logo.png";

// * React icons
import { AiOutlineAppstore } from "react-icons/ai";
import { useMediaQuery } from "react-responsive";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { NavLink, useLocation, Link } from "react-router-dom";

import { FaCode } from "react-icons/fa";
import { MdOutlineWorkHistory } from "react-icons/md";
import { PiReadCvLogoFill } from "react-icons/pi";
import { IoPerson } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import Socials from "../Socials";

const Sidebar = () => {
  let isTabletMid = useMediaQuery({ query: "(max-width: 767px)" });
  const [open, setOpen] = useState(isTabletMid ? false : true);
  const sidebarRef = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    if (isTabletMid) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isTabletMid]);

  useEffect(() => {
    isTabletMid && setOpen(false);
  }, [pathname, isTabletMid]);

  const Nav_animation = {
    open: {
      x: 0,
      width: "16rem",
      transition: {
        damping: 40,
      },
    },
    closed: {
      x: -250,
      width: 0,
      transition: {
        damping: 40,
        delay: 0.15,
      },
    },
  };

  // const subMenusList = [
  //   {
  //     name: "Blog",
  //     path: "blog",
  //     icon: BsPerson,
  //     menus: [
  //       { name: "new post", path: "write" },
  //       { name: "drafts", path: "drafts" },
  //       { name: "published", path: "published" },
  //     ],
  //   },
  // ];

  return (
    <div className="fixed">
      <button
        onClick={() => setOpen(false)}
        className={`md:hidden fixed inset-0 z-[998] bg-black/50 ${
          open ? "block" : "hidden"
        } `}
      ></button>

      <motion.div
        ref={sidebarRef}
        variants={Nav_animation}
        initial={{ x: isTabletMid ? -250 : 0 }}
        animate={open ? "open" : "closed"}
        className=" bg-bgDarker text-gray shadow-xl z-[999] max-w-[16rem] w-[16rem] md:max-w-[13rem]  md:w-[13rem] 
            overflow-y-scoll md:relative fixed h-screen"
      >
        <Link to="/">
          <div className="flex justify-center items-center gap-2.5 font-medium border-b py-10 border-primary mx-3">
            <img src={logo} width={80} alt="" />
          </div>
        </Link>

        <div className="flex flex-col h-full mt-4">
          <ul className="whitespace-pre px-2.5 text-[0.9rem] py-5 flex flex-col gap-1  font-medium overflow-x-hidden scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100  gap-y-4 md:h-[68%] h-[70%]">
            <li>
              <NavLink to={"/"} className="link">
                <AiOutlineAppstore size={23} className="min-w-max" />
                Home
              </NavLink>
            </li>

            <li>
              <NavLink to={"/about"} className="link">
                <IoPerson size={23} className="min-w-max" />
                About
              </NavLink>
            </li>

            <li>
              <NavLink to={"/projects"} className="link">
                <FaCode size={23} className="min-w-max" />
                Projects
              </NavLink>
            </li>

            <li>
              <NavLink to={"/resume"} className="link">
                <MdOutlineWorkHistory size={23} className="min-w-max" />
                Resume
              </NavLink>
            </li>

            <li>
              <NavLink to={"/blog"} className="link">
                <PiReadCvLogoFill size={23} className="min-w-max" />
                Blog
              </NavLink>
            </li>

            <li>
              <NavLink to={"/contact"} className="link">
                <MdOutlineMailOutline size={23} className="min-w-max" />
                Contact
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="absolute h-fit md:block w-full z-50 left-0 right-2 bottom-24 cursor-pointer ml-6">
          <Socials />
        </div>
      </motion.div>

      <div className="md:hidden flex justify-between min-w-[100%] shadow-xl p-3 items-center bg-bgDarker border-b border-primary shadow-primary/5">
        <button onClick={() => setOpen(true)}>
          <AiOutlineMenuUnfold size={25} className="text-primary" />
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
