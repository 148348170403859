import React from "react";
import SectionTitle from "../../components/SectionTitle";
import { useProfileContext } from "../../context/ProfileContext";
import { ServiceCard } from "../../components/ServiceCard";
import { FaLaptopCode } from "react-icons/fa";
import { VscServerProcess } from "react-icons/vsc";
import { GiRobotLeg } from "react-icons/gi";
import { TbDeviceMobileCode } from "react-icons/tb";
import { LiaEthereum } from "react-icons/lia";
import { GrSystem } from "react-icons/gr";
import TechCard from "../../components/TechCard";
import { useSkillsContext } from "../../context/SkillsContext";

const services = [
  {
    title: "Frontend Developer",
    icon: <FaLaptopCode size={50} />,
  },
  {
    title: "Backend Developer",
    icon: <VscServerProcess size={50} />,
  },
  {
    title: "AI & Machine Learning",
    icon: <GiRobotLeg size={50} />,
  },
  {
    title: "Mobile Application Development",
    icon: <TbDeviceMobileCode size={50} />,
  },
  {
    title: "Web3 & DApps",
    icon: <LiaEthereum size={50} />,
  },
  {
    title: "System Design",
    icon: <GrSystem size={50} />,
  },
];

function AboutPage() {
  const { userProfile } = useProfileContext();

  const { allSkills } = useSkillsContext();

  return (
    <div
      className="px-4 md:px-12 py-24 md:pt-12"
      // data-aos="fade-right"
      // data-aos-duration="1200"
    >
      <SectionTitle pageName={"About"} title={"About Me"} />

      <div
        className="w-full h-[30rem] bg-center mb-[30px] bg-cover mt-6"
        style={{ backgroundImage: `url(${userProfile?.profilePhoto})` }}
        data-aos="fade-right"
        data-aos-duration="1200"
      ></div>

      <div
        className="w-full border-solid border-gray-600 border-b pb-[20px] text-gray-300"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <h3 className="text-[22px] font-bold">
          {userProfile?.firstName} {userProfile?.lastName}
        </h3>
        <span>{userProfile?.profession || "Software Engineer"}</span>
      </div>

      <div
        className="w-full border-solid border-gray-600 border-b py-12 text-gray-300"
        data-aos="fade-up"
        // data-aos-duration="1200"
      >
        {userProfile?.profile.split("\n").map((line, index) => (
          <p className="pt-[3px]" key={index}>
            {line}
          </p>
        ))}
      </div>

      {/* skills here */}
      <div className="mt-12">
        <h3 className="text-[22px] font-bold text-gray-300" data-aos="fade-up">
          Programming Languages & Tools
        </h3>

        {/* tools here */}
        <div className="grid grid-cols-3 gap-4 md:grid-cols-6 mt-6">
          {allSkills.map((skill) => {
            return (
              <TechCard
                key={skill?._id}
                name={skill?.skill}
                logo={skill?.logo}
              />
            );
          })}
        </div>
      </div>

      {/* skills - services - interests */}
      <div className="w-full mt-12 text-gray-300">
        <h3 className="text-[22px] font-bold">Services and Interests</h3>
        <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
