const Map = () => {
  return (
    <div className="w-full h-auto mb-[30px]">
      <iframe
        width="100%"
        title="Accra, Ghana"
        height={355}
        id="accra-ghana-map"
        src="https://maps.google.com/maps?q=accra,%20ghana&t=k&z=13&ie=UTF8&iwloc=&output=embed"
      />

      {/* Get your API here https://www.embedgooglemap.net */}
    </div>
  );
};
export default Map;