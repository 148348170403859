import React, { useEffect, useRef } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { useBlogContext } from "../../context/BlogContext";
import MDEditor from "@uiw/react-md-editor";

function BlogReadPage() {
  const { getPostById, postDetails, isLoading } = useBlogContext();
  const navigator = useNavigate();
  const { blogId } = useParams();

  useEffect(() => {
    getPostById(blogId);
  }, [blogId]);

  return (
    <div className="px-8 py-24 md:p-12 h-full overflow-hidden">
      <button
        className="flex gap-3 text-bgDarker items-center w-max px-4 py-1 bg-primary"
        onClick={() => navigator("/blog")}
      >
        <IoMdArrowBack size={20} className="" />
        <p>All Blogs</p>
      </button>

      <div className="mt-12 w-full h-full text-gray-300">
        <MDEditor.Markdown
          source={postDetails?.blogContent}
          style={{ backgroundColor: "transparent" }}
        />
      </div>
    </div>
  );
}

export default BlogReadPage;
