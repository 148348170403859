import React from "react";
import { CiImageOn } from "react-icons/ci";

function BlogShimmer() {
  return (
    <div className="h-max bg-[#242430] mt-2 shadow-md">
      <div className="animate-pulse">
        <div className="flex items-center justify-center w-full pt-3">
          <CiImageOn
            size={120}
            className="text-gray-700 animate-pulse opacity-60"
          />
        </div>

        <div className="px-4 pb-4">
          <div className="w-36 bg-gray-700 rounded h-2 mb-4 mt-4"></div>
          <div className="w-full bg-gray-700 rounded h-4 mb-1"></div>
          <div className="w-[80%] bg-gray-700 rounded h-4 mb-4"></div>

          <div className="mt-4">
            <div className="mt-2 w-full bg-gray-700 rounded h-2"></div>
            <div className="mt-2 w-full bg-gray-700 rounded h-2"></div>
            <div className="mt-2 w-[50%] bg-gray-700 rounded h-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogShimmer;
