import React from "react";
import { Link } from "react-router-dom";
import { convertDate } from "../../../utils/formatDate";

const BlogCard = ({
  _id,
  blogCoverImage,
  publishedAt,
  blogHeading,
  title,
  blogSeries,
  tags,
  blogContent,
}) => {
  return (
    <Link
      to={`/blog/${_id}`}
      state={{
        blog: {
          _id,
          blogCoverImage,
          publishedAt,
          blogHeading,
          title,
          blogSeries,
          blogContent,
          tags,
        },
      }}
    >
      <div
        className="group shadow-xl hover:shadow-primary/10 overflow-hidden transition-transform duration-300 transform"
        data-aos="zoom-in-up"
      >
        {/* cover image */}
        <div
          className="bg-cover bg-center h-[200px] w-full bg-bgDarker transition-transform duration-300 transform group-hover:scale-105"
          style={{ backgroundImage: `url(${blogCoverImage})` }}
        ></div>

        {/* content */}
        <div className="p-4 bg-[#1E1E28] border-t-[4px] border-t-primary relative ">
          {/* Blog category */}
          <div className="mb-4 flex justify-between">
            <p className="text-gray-300 text-sm">{convertDate(publishedAt)}</p>
            <p className="text-sm text-white rounded-xl px-2 w-max bg-blue-800">
              {blogSeries}
            </p>
          </div>

          {/* Blog title */}
          <div>
            <h3 className="text-gray-300 text-lg font-semibold transition-colors duration-300 group-hover:text-primary">
              {title}
            </h3>
          </div>

          {/* Blog description text */}
          <div className="mt-2">
            <p className="text-gray-300 text-sm truncate">{blogHeading}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
