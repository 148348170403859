import React from "react";
import SectionTitle from "../../components/SectionTitle";
import BlogCard from "./components/BlogCard"; // Assuming you have a BlogCard component
import { useBlogContext } from "../../context/BlogContext"; // Update the context import
import BlogShimmer from "./components/BlogShimmer"; // Assuming you have a BlogShimmer component

const BlogPage = () => {
  const { allPosts, isLoading } = useBlogContext(); // Update context functions and properties

  return (
    <div className="px-8 py-24 md:p-12 h-full overflow-hidden">
      <SectionTitle pageName={"Blog"} title={"All Blog Posts"} />

      {/* {!isLoading && allBlogs.length === 0 && (
        <div className="flex w-full items-center justify-center text-gray-400 h-full text-xl text-center">
          <p>
            Oopsie-doodle! My blogs are backstage getting ready for their big
            content reveal. It's not just a showcase; it's a digital storytelling session!
            📚✨ Stay tuned for the narrative magic! 🚀😺
          </p>
        </div>
      )} */}

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8">
        {isLoading
          ? Array.from({ length: 6 }).map((_, index) => (
              <BlogShimmer key={index} />
            ))
          : allPosts.map((post) => <BlogCard key={post._id} {...post} />)}
      </div>
    </div>
  );
};

export default BlogPage;
