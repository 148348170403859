import React from "react";
import { RotateLoader } from "react-spinners";

const LoadingScreen = () => {
  return (
    <div className="w-full h-screen flex items-center justify-center bg-bgDarker">
      <div className="text-center text-white">
        <RotateLoader color="#FFC107" size={100} />
        <p className="mt-4 text-primary font-semibold">Setting Things Up</p>
      </div>
    </div>
  );
};

export default LoadingScreen;
