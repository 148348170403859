import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/ApplicationContext";
import { useEffect } from "react";
import { useProfileContext } from "../../context/ProfileContext";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { TiArrowRight } from "react-icons/ti";

function ApplicationPage() {
  const { searchByTag, applicationDetails } = useAppContext();
  const { userProfile } = useProfileContext();
  const { searchTag } = useParams();

  const navigator = useNavigate();

  useEffect(() => {
    searchByTag(searchTag);
  }, [searchTag]);

  return (
    <div className="w-full py-8 px-4">
      <div className="flex justify-center items-center">
        <img
          src={applicationDetails?.companyLogo}
          alt={`${applicationDetails?.companyName} logo`}
          className="h-[200px] w-[200px] object-cover rounded-2xl"
        />
      </div>

      <div className="my-6 text-gray-300">
        <p className="text-left text-2xl">{applicationDetails?.headline}</p>

        <p className="mt-4 text-primary font-extrabold text-xl">
          More importantly, let me tell you why I have what it takes for the
          {` ${applicationDetails?.jobTitle}`} role.
        </p>
      </div>

      <hr className="my-8 border border-gray-700" />

      <div className="">
        {/* <h3 className="text-primary text-2xl font-extrabold">Profile</h3> */}

        <div
          className="flex flex-col w-full mt-2"
          // data-aos="fade-left"
        >
          <p className="text-gray-300 text-lg">{userProfile?.profile}</p>
        </div>
      </div>

      <div className="grid grid-cols-2 mt-10 gap-6">
        <div className="w-full">
          <h2 className="text-primary">What you want?</h2>
        </div>
        <div className="w-full">
          <h2 className="text-primary">What I bring?</h2>
        </div>
      </div>

      {applicationDetails?.responsibilities &&
        applicationDetails?.responsibilities.map((resp) => {
          return (
            <div
              className="grid grid-cols-2 mt-10 gap-6"
              key={applicationDetails?._id}
            >
              <div className="text-gray-300">
                <p>"{resp.job}"</p>
              </div>

              <div className="text-gray-300">
                {resp?.mine.map((pnt, index) => (
                  <div className="flex gap-1 mb-4">
                    <span className="pt-[5px] text-primary pr-2">
                      <TiArrowRight size={18} />
                    </span>
                    <p key={index}>{pnt}</p>
                  </div>
                ))}
              </div>
            </div>
          );
        })}

      <div className="flex justify-center w-full my-12">
        <hr className="w-52 border border-primary" />
      </div>

      <div className="flex flex-col justify-center text-center w-full text-xl text-gray-300 pb-12">
        <p>Thank you for taking the time to read this.</p>
        <p className="flex items-center">
          <span className="w-[90%]">
            I'd love to continue the conversation—my contact details are listed
            on my resume.
          </span>

          <button onClick={() => navigator("/resume")}>
            <BsFillArrowRightCircleFill className="text-primary" />
          </button>
        </p>
      </div>
    </div>
  );
}

export default ApplicationPage;
