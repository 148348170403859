import { BrowserRouter } from "react-router-dom";
import ApplicationRoutes from "./routes";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import AOS from "aos";
import AnimatedCursor from "react-animated-cursor";
import { useMediaQuery } from "react-responsive";

function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <>
      {isDesktopOrLaptop && (
        <AnimatedCursor
          innerSize={10}
          outerSize={40}
          color="255, 255, 255"
          outerAlpha={0.3}
          innerScale={0.7}
          outerScale={1.2}
          outerStyle={{
            mixBlendMode: "exclusion",
          }}
        />
      )}
      <Toaster />
      <BrowserRouter>
        <ApplicationRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
