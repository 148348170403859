const SectionTitle = ({ pageName, title }) => {
  return (
    <div className="left">
      <span className="inline-block uppercase py-[4px] px-[10px] font-semibold text-[12px] text-[#333] font-montserrat tracking-[0px] mb-[11px] bg-primary">
        {pageName}
      </span>
      <h3 className="font-extrabold font-montserrat text-gray-300">{title}</h3>
    </div>
  );
};
export default SectionTitle;
