import React from "react";
import ReactDOM from "react-dom/client";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import App from "./App.jsx";
import "./index.css";
import { ProfileProvider } from "./context/ProfileContext";
import { ProjectsProvider } from "./context/ProjectContext.js";
import { SkillsProvider } from "./context/SkillsContext.js";
import { EducationProvider } from "./context/EducationContext.js";
import { ResumeProvider } from "./context/ResumeContext.js";
import { ContactProvider } from "./context/ContactContext.js";
import { BlogProvider } from "./context/BlogContext.js";
import { AppProvider } from "./context/ApplicationContext.js";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ProfileProvider>
      <ProjectsProvider>
        <SkillsProvider>
          <EducationProvider>
            <ResumeProvider>
              <ContactProvider>
                <BlogProvider>
                  <AppProvider>
                    <App />
                  </AppProvider>
                </BlogProvider>
              </ContactProvider>
            </ResumeProvider>
          </EducationProvider>
        </SkillsProvider>
      </ProjectsProvider>
    </ProfileProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
