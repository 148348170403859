import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { useProfileContext } from "../../context/ProfileContext";
import LoadingScreen from "../LoadingScreen";
// import Navbar from "../Navbar";

function MainLayout() {
  const { isSettingUp, isLoading } = useProfileContext();

  const { pathname } = useLocation();

  return (
    <div>
      {isSettingUp && isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="flex">
          {/* sidebar */}
          <div className="md:w-[13rem] z-[99]">
            <Sidebar />
          </div>

          {/* site content */}
          <main className="flex-1">
            <div className="min-h-[100vh] md:pt-0 bg-bgDark lg:flex lg:justify-center">
              {pathname === "/" ? (
                <div className="w-full">
                  <Outlet />
                </div>
              ) : (
                <div className="lg:max-w-[1080px] lg:w-full">
                  <Outlet />
                </div>
              )}
            </div>
          </main>
        </div>
      )}
    </div>
  );
}

export default MainLayout;
