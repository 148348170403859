import React from "react";
import { RiGraduationCapFill } from "react-icons/ri";
import { TiArrowRight } from "react-icons/ti";
import { formatDate } from "../../../utils/formatDate";

// {
//     _id: '6580524f987b90392a5c31af',
//     institution: 'University of Example',
//     type: 'Bachelor of Science',
//     fieldOfStudy: 'Computer Science',
//     startDate: '2020-09-01T00:00:00.000Z',
//     endDate: '2024-05-30T00:00:00.000Z',
//     description: 'Studied various aspects of computer science and programming.',
//     createdAt: '2023-12-18T14:08:15.119Z',
//     updatedAt: '2023-12-18T14:08:15.119Z',
//     __v: 0
//   },

function EducationCard({
  institution,
  description,
  fieldOfStudy,
  type,
  startDate,
  endDate,
  _id,
}) {
  return (
    <div
      className="border-l border-gray-600 h-max bg-bgDarker pb-6 pr-4"
      data-aos="fade-up"
    >
      <div className="flex justify-between">
        <div className="flex items-center gap-x-4">
          <RiGraduationCapFill
            className="text-primary bg-bgDarker p-[6px] rounded-full -ml-4"
            size={35}
          />
        </div>
      </div>

      <div className="pl-6 md:pl-10 -mt-7">
        <div className="flex flex-col md:flex-row md:justify-between">
          <div>
            <h5 className="uppercase text-lg text-gray-400">
              {type} - {fieldOfStudy}
            </h5>

            <h6 className="text-gray-400 text-sm italic">{institution}</h6>
          </div>

          <span className="px-[10px] w-max h-max bg-gray-700 text-gray-300 rounded-full text-sm uppercase items-center flex mt-2 md:mt-0">
            {formatDate(startDate)} {endDate ? "- " + formatDate(endDate) : ""}
          </span>
        </div>

        {description.split("\n").map((val, i) => {
          return (
            <p className="pt-3 text-gray-400 flex text-sm" key={i}>
              <span className="pt-[5px] text-primary pr-2">
                <TiArrowRight />
              </span>
              {val}
            </p>
          );
        })}
      </div>
    </div>
  );
}

export default EducationCard;
