import React, { createContext, useContext, useEffect, useState } from "react";
import { apiConnect } from "../api/axios";
import toast from "react-hot-toast";

const BlogContext = createContext();

export const BlogProvider = ({ children }) => {
  const [allPosts, setAllPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [postDetails, setPostDetails] = useState(null);

  function handleError(error) {
    const { response } = error;
    // toast.error(
    //   response?.data?.message ?? "An error occurred, please try again!",
    //   { position: "top-center" }
    // );
  }

  const getAllPosts = async () => {
    try {
      setIsLoading(true);
      const response = await apiConnect.getAllBlogs();

      if (response.status === 200) {
        setAllPosts(response.data);
      } else {
        toast.error("Failed to fetch posts. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPostById = async (id) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.getBlogById(id);

      if (response.status === 200) {
        setPostDetails(response.data);
      } else if (response.status === 404) {
        toast.error("Post not found.");
      } else {
        toast.error("Failed to fetch post details. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllPosts();
  }, []);

  return (
    <BlogContext.Provider
      value={{
        allPosts,
        isLoading,
        postDetails,
        getAllPosts,
        getPostById,
        setPostDetails
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};

export const useBlogContext = () => {
  const context = useContext(BlogContext);
  if (!context) {
    throw new Error(
      "useBlogContext must be used within a BlogProvider"
    );
  }
  return context;
};
