import { TypeAnimation } from "react-type-animation";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import ParallaxBackground from "../../components/ParallaxBG";
import { useProfileContext } from "../../context/ProfileContext";

export default function Home() {
  const navigator = useNavigate();
  const { userProfile } = useProfileContext();

  return (
    <ParallaxBackground>
      <div data-aos="fade-right" data-aos-duration="1200" className="-pt-24">
        <div className="flex flex-col md:flex-row items-center h-screen w-full z-50 justify-center md:justify-evenly">
          {/* image */}
          <div
            className={`w-52 h-52 md:w-[30%] md:h-[45%] border-[5px] border-primary shadow-xl shadow-primary/20 rounded-full bg-cover bg-center`}
            style={{
              backgroundImage: `url(${userProfile?.profilePhoto})`,
            }}
          ></div>

          {/* about */}
          <div className="w-full md:w-[50%] px-4 md:px-0 mt-4 md:mt-0 text-center md:text-left">
            <h3 className="text-[30px] md:text-[55px] font-extrabold uppercase mb-[5px] text-primary">
              {userProfile?.firstName} <span>{userProfile?.lastName}</span>
            </h3>

            <TypeAnimation
              sequence={[
                // Same substring at the start will only be typed once, initially
                "SWE",
                2500,
                "Frontend",
                2500,
                "Backend",
                2500,
                "Mobile Application",
                2500,
              ]}
              speed={50}
              style={{ fontSize: "1.5em" }}
              repeat={Infinity}
              className="text-gray-300"
            />

            <p className="font-medium max-w-[450px] my-[20px] text-gray-300">
              {userProfile?.headline ||
                "I am a Problem Solver at heart. I create features that best solves the problem at hand."}
            </p>

            {/* socials */}
            {/* <Socials /> */}

            <div className="flex justify-center md:justify-start mt-6">
              <button
                className="border border-primary text-primary p-3 rounded-xl flex items-center justify-center gap-4 hover:bg-primary hover:text-bgDarker hover:scale-105 transition delay-150 duration-300 ease-in-out"
                onClick={() => navigator("/about")}
              >
                More About Me
                <span>
                  <IoArrowForwardCircleOutline size={25} />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ParallaxBackground>
  );
}
