import React, { createContext, useContext, useEffect, useState } from "react";
import { apiConnect } from "../api/axios";
import toast from "react-hot-toast";

const EducationContext = createContext();

export const EducationProvider = ({ children }) => {
  const [allEducation, setAllEducation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [educationDetails, setEducationDetails] = useState(null);

  function handleError(error) {
    const { response } = error;
    // toast.error(
    //   response?.data?.message ?? "An error occurred, please try again!",
    //   { position: "top-center" }
    // );
  }

  const getAllEducation = async () => {
    try {
      setIsLoading(true);
      const response = await apiConnect.getAllEducation();

      if (response.status === 200) {
        setAllEducation(response.data);
      } else {
        toast.error(
          "Failed to fetch education details. Please try again later."
        );
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getEducationById = async (id) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.getEducationById(id);

      if (response.status === 200) {
        setEducationDetails(response.data);
      } else if (response.status === 404) {
        toast.error("Education details not found.");
      } else {
        toast.error(
          "Failed to fetch education details. Please try again later."
        );
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllEducation();
  }, []);

  return (
    <EducationContext.Provider
      value={{
        allEducation,
        isLoading,
        educationDetails,
        getAllEducation,
        getEducationById,
      }}
    >
      {children}
    </EducationContext.Provider>
  );
};

export const useEducationContext = () => {
  const context = useContext(EducationContext);
  if (!context) {
    throw new Error(
      "useEducationContext must be used within an EducationProvider"
    );
  }
  return context;
};
