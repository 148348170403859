// ParallaxBackground.js
import React, { useRef, useState, useEffect } from "react";

const ParallaxBackground = ({ children }) => {
  const backgroundRef = useRef(null);
  const [backgroundPosition, setBackgroundPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      const { offsetWidth, offsetHeight } = backgroundRef.current;

      // Calculate the percentage position of the cursor within the background
      const xPercentage = (clientX / offsetWidth) * 100;
      const yPercentage = (clientY / offsetHeight) * 100;

      // Calculate the background position based on the cursor position
      const newX = -(xPercentage - 50) * 0.2;
      const newY = -(yPercentage - 50) * 0.2;

      setBackgroundPosition({ x: newX, y: newY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div
      ref={backgroundRef}
      className="relative h-screen bg-cover bg-center overflow-hidden"
      style={{
        backgroundImage:
          "url(https://unsplash.com/photos/ctRJMubyj4o/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8Mzl8fGFic3RyYWN0fGVufDB8MHx8fDE3MDMwNTg1NzB8MA&force=true&w=1920)",
        backgroundPosition: `${backgroundPosition.x}% ${backgroundPosition.y}%`,
      }}
    >
      {/* Overlay */}
      <div
        className="absolute inset-0 bg-bgDark opacity-95"
        // style={{ mixBlendMode: 'multiply' }}
      ></div>

      {children}
    </div>
  );
};

export default ParallaxBackground;
