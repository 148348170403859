import { useRoutes } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import ErrorRoutes from "./ErrorRoutes";
import NoSidebarRoutes from "./NoSidebarRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ApplicationRoutes() {
  return useRoutes([MainRoutes, ErrorRoutes, NoSidebarRoutes]);
}
