import { Navigate } from "react-router-dom";
import Dashboard from "../pages/Home/Home";
import Contact from "../pages/Contact/Contact";
import ProjectPage from "../pages/Projects/ProjectsPage";
import AboutPage from "../pages/About/AboutPage";
import ResumePage from "../pages/Resume/ResumePage";
import BlogPage from "../pages/Blog/BlogPage";
import BlogReadPage from "../pages/Blog/BlogReadPage";
import MainLayout from "../components/Layout/MainLayout";
// import ProjectDetails from "../pages/Projects/ProjectDetails";

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    //   ================= dashboard =================
    {
      path: "/",
      element: <Dashboard />,
    },

    //  ==================== End Dashboard =========================

    // ================= other routes goes here ===================

    {
      path: "about",
      element: <AboutPage />,
    },

    {
      path: "contact",
      element: <Contact />,
    },

    {
      path: "/blog",
      children: [
        {
          path: "",
          element: <BlogPage />,
        },

        {
          path: ":blogId",
          element: <BlogReadPage />,
        },
      ],
    },

    {
      path: "/projects",
      children: [
        {
          path: "",
          element: <ProjectPage />,
        },

        // {
        //   path: ":projectID",
        //   element: <ProjectDetails />,
        // },
      ],
    },

    {
      path: "/resume",
      children: [
        {
          path: "",
          element: <ResumePage />,
        },
      ],
    },

    //  ======================== End Other routes =================

    // NB: Keep this as the last route
    // ========== 404 pages ====================

    {
      path: "*",
      element: <Navigate to="/" />,
    },

    //  =======================
  ],
};

export default MainRoutes;
