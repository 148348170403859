import React, { createContext, useContext, useEffect, useState } from "react";
import { apiConnect } from "../api/axios";
import toast from "react-hot-toast";

const SkillsContext = createContext();

export const SkillsProvider = ({ children }) => {
  const [allSkills, setAllSkills] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [skillDetails, setSkillDetails] = useState(null);

  function handleError(error) {
    const { response } = error;
    // toast.error(
    //   response?.data?.message ?? "An error occurred, please try again!",
    //   { position: "top-center" }
    // );
  }

  const getAllSkills = async () => {
    try {
      setIsLoading(true);
      const response = await apiConnect.getAllSkills();

      if (response.status === 200) {
        setAllSkills(response.data);
      } else {
        toast.error("Failed to fetch skills. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSkillById = async (id) => {
    try {
      setIsLoading(true);
      const response = await apiConnect.getSkillById(id);

      if (response.status === 200) {
        setSkillDetails(response.data);
      } else if (response.status === 404) {
        toast.error("Skill not found.");
      } else {
        toast.error("Failed to fetch skill details. Please try again later.");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllSkills();
  }, []);

  return (
    <SkillsContext.Provider
      value={{
        allSkills,
        isLoading,
        skillDetails,
        getAllSkills,
        getSkillById,
      }}
    >
      {children}
    </SkillsContext.Provider>
  );
};

export const useSkillsContext = () => {
  const context = useContext(SkillsContext);
  if (!context) {
    throw new Error("useSkillsContext must be used within a SkillsProvider");
  }
  return context;
};
